@import "@alltrails/shared/styles/landsEnd.scss";
.avatar {
  height: 48px;
  width: 48px;
  background: var(--color-neutral-200);
  border-radius: var(--radius-md);
  display: flex;
  justify-content: center;
  align-items: center;
}
