@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  @include flex-row;
  padding: var(--space-200);
  background-color: var(--color-blue-100);
  align-items: flex-start;
  cursor: pointer;
  position: relative;
}

.icon {
  display: inline-block;
  padding-right: var(--space-100);
}

.content {
  @include flex-column;
  gap: var(--space-200);
}

.note {
  @include text-100;
}

.editIconContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--space-50) var(--space-200) 0 0;
  display: none;
}

.container:hover .editIconContainer {
  display: block;
}
