@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  @include flex-row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--space-200);
  max-width: 100%;
}

.labelStack {
  @include flex-column;
  min-width: 0;
}

.labelRow {
  @include flex-row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.title,
.subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.title {
  @include text-200-bold;
  flex: 1 0 auto;

  &:not(.withExtension) {
    width: 100%;
  }
}

.proBadge {
  align-self: center;
  flex: 1 0 auto;
  margin-right: var(--space-100);
}

.extension {
  flex-wrap: wrap;
  @include text-200;
  line-height: 1.38; // to match text-200-bold line height
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 550px;
  margin-left: var(--space-50);

  a {
    @include text-200-bold;
  }
}

.subtitle {
  @include text-100;
  color: var(--color-text-secondary);
}

.subtitle:nth-child(3) {
  @include text-100-bold;
  text-decoration: underline !important;
}

.tertiaryText {
  @include text-100-bold;
  text-decoration: underline;
}

.rating {
  margin-right: var(--space-100);
  margin-top: 3px;
}
