@import "@alltrails/shared/styles/landsEnd.scss";
.link {
  transition: all 0.2s ease-out;
  text-decoration: underline;
  text-underline-offset: var(--space-50);

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &.noUnderline {
    text-decoration: none;

    @include hover {
      text-decoration: underline;
    }
  }
}

button.link {
  @include removeDefaultButtonStyles;
  cursor: pointer;
}

.sm {
  @include text-100;
}

.md {
  @include text-200;
}

.lg {
  @include text-300;
}

.primary {
  color: var(--color-text-primary);

  &:focus-visible {
    box-shadow: 0 0 0 var(--borders-weight-sm) var(--color-interactive-primary-hover);
  }

  @include hover {
    &.noUnderline {
      color: var(--color-interactive-primary-hover);
    }
    color: var(--color-text-secondary);
  }
}

.secondary {
  color: var(--color-text-secondary);

  &:focus-visible {
    box-shadow: 0 0 0 var(--borders-weight-sm) var(--color-text-secondary);
  }

  @include hover {
    &.noUnderline {
      color: var(--color-text-secondary);
    }
    color: var(--color-interactive-primary-hover);
  }
}

.inverse {
  color: var(--color-text-primary-inverse);

  &:focus-visible {
    box-shadow: 0 0 0 var(--borders-weight-sm) var(--color-text-primary-inverse);
  }

  @include hover {
    &.noUnderline {
      color: var(--color-text-primary-inverse);
    }
    color: var(--color-text-secondary);
  }
}

.icon {
  display: inline-flex;
  gap: var(--space-100);
  align-items: center;
}
