@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-y: hidden;
  max-height: 500px;
}

.visible {
  visibility: visible;
}
