@import "@alltrails/shared/styles/landsEnd.scss";
.tag {
  @include removeDefaultButtonStyles;
  margin: 0; // Needed to remove default margin on some browsers when an anchor tag is rendered
  display: inline-flex;
  flex-direction: row;
  gap: var(--space-100);
  box-sizing: border-box;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-sm);
  border: 1px solid var(--color-border-separator);
  transition: all 0.2s ease-out;

  &:focus,
  &:focus-visible {
    outline: none;
  }

  svg {
    flex-shrink: 0;
  }

  .textContent {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.sm {
  @include text-100;
  color: var(--color-text-secondary);
  height: 24px;
  padding: 2px var(--space-100);
}

.md {
  @include text-200;
  color: var(--color-text-secondary);
  height: 32px;
  padding: var(--space-50) var(--space-150);
}

button.tag,
a.tag {
  color: var(--color-text-secondary !important);

  &:focus-visible {
    text-decoration: underline;
    color: var(--color-text-secondary-hover);
  }

  @include hover {
    text-decoration: underline;
    color: var(--color-text-secondary-hover);
  }
}
