@import "@alltrails/shared/styles/landsEnd.scss";
.borderBottomContainer {
  width: 100%;
}

.borderBottom {
  width: 100%;
  height: var(--borders-weight-sm);
  background-color: var(--color-border-separator);
}

.container {
  padding: calc($space-24 - var(--borders-weight-lg)) 0px;
}

.headerContent {
  @include flex-row;
  align-items: center;
}

.headerText {
  @include flex-column;
  width: 100%;
}

.trigger {
  @include flex-row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: var(--space-300);
  padding: 0px;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: var(--borders-weight-lg) solid transparent;
  border-radius: var(--radius-md);

  &:focus-visible {
    border: var(--borders-weight-lg) solid var(--color-border-focus-default);
  }
}

.heading {
  @include flex-column;
  align-items: flex-start;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
}

.subtitle {
  margin-top: var(--space-50);
}

.leadingVisual {
  margin-right: var(--space-200);
}

.row {
  @include flex-row;
  align-items: center;
  gap: var(--space-100);
}

.trailingText {
  @include text-200;
  color: var(--color-text-secondary);
}
