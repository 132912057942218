@import "@alltrails/shared/styles/landsEnd.scss";
// https://www.figma.com/file/mh7ECfwLfHr8jTIaAYOS80/Trail-Cards?type=design&node-id=6-25282&mode=design&t=xNQhNCFkPZZZbgS6-4

.wrapper {
  height: 40px;
  width: 100%;

  &.gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  }

  .paginationElement {
    position: absolute;
    text-align: center;
    transition: var(--duration-short) opacity;
    transform: translate3d(0, 0, 0);
    bottom: $space-16;

    :global(.swiper-pagination-bullet) {
      opacity: 0.4;
      background-color: var(--color-brand-dark);
    }

    :global(.swiper-pagination-bullet-active) {
      opacity: 1;
    }
  }
}
