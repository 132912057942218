@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  animation: rotate 1.5s infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
